
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');

/*
window.Vue = require('vue');
*/

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/*
Vue.component('example-component', require('./components/ExampleComponent.vue'));

const app = new Vue({
    el: '#app'
});
*/

/* Collapse header on scroll */
$(window).scroll(function() {

  if ($( "header" ).hasClass( "home" )) {
    var breakpoint = 250;
  } else {
    var breakpoint = 100;
  }

  if ($(this).scrollTop() > breakpoint){
      $('header').addClass("sticky");
  }
  else{
      $('header').removeClass("sticky");
  }
});

/* Animate scrolling to #anchors */
$("a[href^='#']").on('click', function(event) {

  var target;
  target = this.hash;

  event.preventDefault();


  var navOffset = 68;
  // navOffset = $('header').height();
  // console.log(navOffset);

  return $('html, body').animate({
    scrollTop: $(this.hash).offset().top - navOffset
  }, 300, function() {
    return window.history.pushState(null, null, target);
  });
});


$('.blink').each(function() {
    var elem = $(this);
    setInterval(function() {
        if (elem.css('visibility') == 'hidden') {
            elem.css('visibility', 'visible');
        } else {
            elem.css('visibility', 'hidden');
        }
    }, 3000);
});
